import { useState } from "react"
import { Collapse, Button, CardBody, Card } from "reactstrap"
import { FaChevronDown } from "react-icons/fa"
import faq from "../../data/faq"
import "./index.scss"

const HowDoI = () => {
  const [isOpen, setIsOpen] = useState(0)

  return (
    <div id={"faq-wrap"} name={"faq-wrap"}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 text-center mb-5 pb-2">
            <h2 className={"lined-title"}>
              <span>How Do I…?</span>
            </h2>
          </div>

          <div className="col-sm-12 col-xl-10 offset-xl-1 faq-wrapper">
            {faq.map(({ q, a }, idx) => (
              <div key={idx} className={`faq-item ${idx === 0 ? "first" : ""}`}>
                <Button
                  color="default"
                  className={isOpen === idx + 1 ? "is-open" : ""}
                  onClick={() => setIsOpen(isOpen === idx + 1 ? 0 : idx + 1)}
                >
                  {q}
                  <FaChevronDown />
                </Button>
                <Collapse isOpen={isOpen === idx + 1}>
                  <Card>
                    <CardBody>
                      <div dangerouslySetInnerHTML={{ __html: a }}></div>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowDoI
