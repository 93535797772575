/**
 * q: the question
 * a: the answer
 * The answer supports HTML markup
 */

const faq = [
  {
    q: "Allow Notification Access",
    a: `<p>OneApp needs to access the Notifications. In order for OneApp to access the Notifications, you must allow OneApp 
        "Notification Access". <br/><br />
        During the installation of OneApp, it takes you to the Notification Access screen, so that you can turn ON the Notification 
        Access. If this was not done during the installation, you will need to do this yourself. <br /><br />
        Please go to Settings, search for "Notification access". On the "Notification access" screen, look for OneApp and Switch ON 
        Notification access.</p>`,
  },
  {
    q: "Configure OneApp",
    a: `<p>The Settings screen allows you to select which Messaging apps you want to see in OneApp and which apps you 
          do not want to see. We are constantly adding apps to this list. So, if your favourite app is not on this list, let us 
          know and we will add it.<br /><br />

          From the Settings screen, you can specify if you want to see ALL notifications on your device or only the Chat Messages. 
          You can also set the look ahead for the upcoming events and decide how many days you want to see.</p>`,
  },
  {
    q: "Timeline view",
    a: `<p>The Timeline view shows all your Messages, from all your apps in a very convenient timeline view.<br /><br />

        You can simply click on the Message to Read the message & Reply to the message.<br /><br />

        You can click on the Chat icon to launch the Messaging App (like WhatsApp or Messenger).<br /><br />

        You can click on the User Icon to see all the Conversations with this User.<br /><br />
        
        You can Swipe to the Left to see the Message History.<br /><br />
        
        Pretty cool!!</p>`,
  },
  {
    q: "Conversation view",
    a: `<p>The Conversation View shows all Messages sent by the same person in one convenient place. So, irrespective of what app 
        was used to send you the message, you can see them all in the Conversation View.<br /><br />

        Simply click on the Person Icon to see the Conversation View for this person</p>`,
  },
  {
    q: "Reply to Messages",
    a: `<p>To Reply to any Message, simply click on the Message. Type your Reply and Press Send. It’s simple as that.<br /><br />

        You can aslo click on the Chat Icon (on the right side) to open the Chat app that sent the message.</p>`,
  },
  {
    q: "Search Messages",
    a: `<p>With One Search, you can Search all Messages from all Apps. Simply click on the Search Icon and type your Search text. Simple as that!!.</p>`,
  },
  {
    q: "Hide Messages",
    a: `<p>For times when you don’t want to keep those Messages any more, it is simple. You just hold down on the messages to select them. Then select Hide or Delete.</p>`,
  },
]

export default faq
