import Image1 from "./images/1.png"
import Image2 from "./images/2.png"
import Image3 from "./images/3.png"
import Image4 from "./images/4.png"
import Image5 from "./images/5.png"
import Image6 from "./images/6.png"

// Importing the icons. You can use any icon from react-icons: https://react-icons.github.io/react-icons/
import {
  FaCog,
  FaEye,
  FaMobileAlt,
  FaPaperPlane,
  FaReply,
  FaSearch,
} from "react-icons/fa"

// These are the images. Please include 6 images.
export const images = [Image1, Image2, Image3, Image4, Image5, Image6]

// These are the descriptions and icons for the first three images
export const content1 = [
  {
    icon: <FaMobileAlt />,
    title: "One simple inbox",
    text: "You can see all messages from all Apps on One simple screen",
  },
  {
    icon: <FaReply />,
    title: "Instant reply",
    text:
      "Reply to your messages in a blink of an eye. No more wasting time to go to each app.",
  },
  {
    icon: <FaCog />,
    title: "Customize your experience",
    text:
      "You can easily customise what you want to see and what you don’t through One App’s settings.",
  },
]

// These are the descriptions and icons for the last three images
export const content2 = [
  {
    icon: <FaEye />,
    title: "Your information is safe",
    text:
      "We value and respect your privacy. Your information always stays on your device & you can delete any unwanted messages.",
  },
  {
    icon: <FaPaperPlane />,
    title: "Conversation view",
    text: "Group all messages from all apps in One simple conversation view.",
  },
  {
    icon: <FaSearch />,
    title: "Search & Organize",
    text:
      "Power up productivity by managing all of your messages from multiple apps. Now you can work from anywhere.",
  },
]
