import { useState } from "react"
import { useForm } from "react-hook-form"
import ContactImage from "./images/contact-icon.png"
import { Input, FormGroup, FormFeedback, Button } from "reactstrap"
import CommonData from "../../data/CommonData"
import "./index.scss"

const Contact = () => {
  const [success, setSuccess] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const submit = async (data, e) => {
    e.target.reset()
    const url = CommonData.contactFormSubmissionEndpoint
    await fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        setSuccess(true)
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <div id={"contact-wrap"} name={"contact-wrap"}>
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center justify-content-center">
            <div className="contact-image-wrapper">
              <img src={ContactImage} alt="Contact us" />
            </div>
          </div>
          <div className="col-sm-12 col-md-6">
            <form onSubmit={handleSubmit(submit)}>
              <div className="text-center mb-4">
                <h3>{CommonData.contactSectionTitle}</h3>
                <p>{CommonData.contactSectionText}</p>
              </div>
              <FormGroup>
                <Input
                  type={"text"}
                  placeholder={"First Name"}
                  {...register("firstName", { required: true })}
                  invalid={errors && errors.firstName ? true : false}
                />
                <FormFeedback>
                  {errors.firstName?.type === "required" &&
                    "First name is required"}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input
                  type={"text"}
                  placeholder={"Last Name"}
                  {...register("lastName", { required: true })}
                  invalid={errors && errors.lastName ? true : false}
                />
                <FormFeedback>
                  {errors.lastName?.type === "required" &&
                    "Last name is required"}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input
                  type={"email"}
                  placeholder={"Email Address"}
                  {...register("email", {
                    required: true,
                    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  })}
                  invalid={errors && errors.email ? true : false}
                />
                <FormFeedback>
                  {errors.email?.type === "required" && "Email is required"}
                  {errors.email?.type === "pattern" && "Email is not valid"}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input
                  type={"textarea"}
                  placeholder={"Message"}
                  {...register("message", { required: true })}
                  invalid={errors && errors.message ? true : false}
                  rows={5}
                />
                <FormFeedback>
                  {errors.message?.type === "required" &&
                    "Message field required"}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Button color={"primary"} type="submit">
                  Send message
                </Button>
                {success && (
                  <p className={"text-success position-absolute mt-2"}>
                    {CommonData.contactFormSuccessMessage}
                  </p>
                )}
              </FormGroup>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
