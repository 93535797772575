import "./index.scss"

import CommonData from "../../data/CommonData"

import { socialList, socialList2 } from "../../data/HeroSectionSocialIcons/data"

const Hero = () => {
  return (
    <div className={"hero-section d-lg-flex align-items-lg-center"}>
      <div className="container">
        <div className="row">
          <div className="col-xl-7">
            <div className="text-container">
              <h1>
                <span>{CommonData.heroSectionTitleBold}</span>{" "}
                {CommonData.heroSectionTitleThin}
              </h1>
              <p>{CommonData.heroSectionText}</p>
              <div className="hero-button-wrapper">
                <a
                  href={CommonData.getOneAppLink}
                  className={"btn btn-primary"}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  Get OneApp
                </a>
                <a
                  href={CommonData.demoVideoLink}
                  target={"_blank"}
                  rel={"noreferrer"}
                  className={"btn btn-play"}
                >
                  Watch OneApp in action
                </a>
              </div>

              <ul className="social-list">
                {socialList.map(({ name, link, icon }) => (
                  <li key={name}>
                    <a href={link}>
                      <img src={icon} alt={name} />
                    </a>
                  </li>
                ))}
              </ul>

              <ul className="social-list">
                {socialList2.map(({ name, link, icon }) => (
                  <li key={name}>
                    <a href={link}>
                      <img src={icon} alt={name} />
                    </a>
                  </li>
                ))}
              </ul>
              <span className={"d-block pt-4"}>More than 20 applications</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
