import WhatsApp from "./icons/whatsapp.png"
import Kakao from "./icons/kakao-talk.png"
import Messenger from "./icons/messenger.png"
import Skype from "./icons/skype.png"
import Instagram from "./icons/instagram.png"
import Telegram from "./icons/telegram.png"
import Wechat from "./icons/wechat.png"
import Twitter from "./icons/twitter.png"

export const socialList = [
  { name: "WhatsApp", link: "#", icon: WhatsApp },
  { name: "Kakao Talk", link: "#", icon: Kakao },
  { name: "Messenger", link: "#", icon: Messenger },
  { name: "Skype", link: "#", icon: Skype },
]

export const socialList2 = [
  { name: "Instagram", link: "#", icon: Instagram },
  { name: "Telegram", link: "#", icon: Telegram },
  { name: "WeChat", link: "#", icon: Wechat },
  { name: "Twitter", link: "#", icon: Twitter },
]
