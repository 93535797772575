import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import ScrollToTop from "./components/ScrollToTop"
import HomePage from "./pages/HomePage"
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage"

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicyPage />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
