import Icon1 from "./icons/hiw-1.png"
import Icon2 from "./icons/hiw-2.png"
import Icon3 from "./icons/hiw-3.png"

const howItWorks = [
  { icon: Icon1, text: "See all your Messages from ALL Apps in One place" },
  { icon: Icon2, text: "Simply tap on messages to Read & Reply" },
  {
    icon: Icon3,
    text: "Easily Search all messages from all Apps with OneApp Search",
  },
]

export default howItWorks
