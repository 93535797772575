import React from "react"
import "./index.scss"

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h3>JUSTONEAPP WEBSITE PRIVACY POLICY</h3>
      <p>Last Updated: May 15, 2021</p>

      <h5 className="text-muted">Introduction</h5>
      <p>
        OneApp is an Universal Inbox that displays all your Messages from your
        Messaging & Chat Apps in one place. OneApp’s mission is to simplify your
        Messaging, making it really easy for you to do your everyday tasks.
      </p>

      <h5 className="text-muted">Privacy Policy</h5>
      <p>
        Maintaining your privacy is our top priority. We follow the below
        guidelines in order to protect your data and privacy:
      </p>

      <ol>
        <li className="py-2">
          Your Chats & Messages always remain in your full control. OneApp does
          not store Messages on a server. The data is always on your device and
          in your full control.
        </li>
        <li className="py-2">
          We have implemented appropriate security and safeguards designed to
          protect your information in accordance with industry standards.
        </li>
        <li className="py-2">
          We protect your personal information and will only provide it to third
          parties: (1) with your consent; (2) where it is necessary to carry out
          your instructions; (3) as reasonably necessary in order to provide our
          features and functionality to you; (4) when we reasonably believe it
          is required by law, subpoena or other legal process; or (5) as
          necessary to enforce our User Agreement or protect the rights,
          property, or safety of our Members and Visitors, and the public.
        </li>
      </ol>

      <h5 className="text-muted">What Data Do We Collect?</h5>
      <p>
        OneApp is a single Inbox for all your Chat & Messaging Apps and
        Messages. To help collect and present the Messages to you in a
        convenient and user friendly manner, OneApp collects and stores the
        following data on your Mobile phone. Please note that your personal data
        never leaves the phone.
      </p>
      <ol>
        <li className="py-2">
          A list of Apps that you select through the OneApp configuration
          screen. You can decide which Apps you want to see via OneApp
        </li>
        <li className="py-2">
          A list of Users who you Chat & Message with. OneApp accesses this
          information from your Contacts list
        </li>
        <li className="py-2">
          The Chat & Messaging App Notifications you receive on the Mobile App.
          These notifications are used to show all messages in one Inbox.
        </li>
      </ol>

      <h5 className="text-muted">Changes to this Policy</h5>
      <p>
        We may change this Policy from time to time. If we make any changes to
        this Policy, we will notify you. After such changes have been notified
        and published to our website, your continued use of OneApp will
        constitute your acceptance of the revised policy.
      </p>

      <h5 className="text-muted">Questions & Feedback</h5>
      <p>
        Please send us your questions, comments and feedback to{" "}
        <a href="mailto:promote@justoneapp.io">promote@justoneapp.io</a>.
      </p>
    </div>
  )
}

export default PrivacyPolicy
