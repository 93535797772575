import Image1 from "./images/1.png"
import Image2 from "./images/2.png"
import Image3 from "./images/3.png"

/**
 *
 * The images should be transparent pngs and the dimensions and image positioning should match 100% with the images that are currently in use.
 * If not, the styles have to be modified.
 * "text" field supports html markup.
 */

const features = [
  {
    image: Image1,
    title: "Customize OneApp",
    text:
      "Do you want OneApp to read your messages? What messages do you want to see? You can control all of this in OneApp Settings.",
  },
  {
    image: Image2,
    title: "Shake to turn voice on/off",
    text:
      "You can simply turn the OneApp Voice assistant on or off by shaking your phone. So, simple!!",
  },
  {
    image: Image3,
    title: "With OneApp, You will never miss a single event again!",
    text: `See all your upcoming calendar appointments in One place.<br />You can even control if you see 1 day or 1 week.`,
  },
]

export default features
