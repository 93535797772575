import GooglePlay from "./images/google-play.png"
import CommonData from "../../data/CommonData"
import "./index.scss"

const CTA = () => {
  return (
    <div className={"cta-wrapper"}>
      <div className="container">
        <div className={"cta-content"}>
          <h2>Experience Now!</h2>
          <a
            href={CommonData.playStoreLink}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <img src={GooglePlay} alt="Get it on Google Play" width={228} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default CTA
