import Image1 from "./images/user.jpg"

/**
 * Does not support html markup. Please use plain text
 * Images to be saved in images folder.
 */

const testimonials = [
  {
    text:
      "This is cool, Now I don't have to check my Email, Whatapp and Facebook all the time. OneApp does it for me. When I am driving, OneApp Voice assistant reads the messages for me and I get to keep my eyes on the road. Just so safe!!",
    name: "John Smith",
    image: Image1,
  },
  {
    text:
      "This is cool, Now I don't have to check my Email, Whatapp and Facebook all the time. OneApp does it for me. When I am driving, OneApp Voice assistant reads the messages for me and I get to keep my eyes on the road. Just so safe!!",
    name: "John Smith",
    image: Image1,
  },
  {
    text:
      "This is cool, Now I don't have to check my Email, Whatapp and Facebook all the time. OneApp does it for me. When I am driving, OneApp Voice assistant reads the messages for me and I get to keep my eyes on the road. Just so safe!!",
    name: "John Smith",
    image: Image1,
  },
  {
    text:
      "This is cool, Now I don't have to check my Email, Whatapp and Facebook all the time. OneApp does it for me. When I am driving, OneApp Voice assistant reads the messages for me and I get to keep my eyes on the road. Just so safe!!",
    name: "John Smith",
    image: Image1,
  },
]

export default testimonials
