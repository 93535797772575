const CommonData = {
  getOneAppLink: "https://play.google.com/store/apps/details?id=io.justoneapp",
  playStoreLink: "https://play.google.com/store/apps/details?id=io.justoneapp",
  demoVideoLink: "https://youtu.be/ScEr_fiJKSc",
  facebookProfile: "https://www.facebook.com/justoneapp",
  twitterProfile: "https://twitter.com/justoneapp",
  instagramProfile: "https://www.instagram.com/justoneapp2021/",

  heroSectionTitleBold: "Messaging",
  heroSectionTitleThin: "made simple",
  heroSectionText: "See ALL messages from ALL Apps in One Inbox.",

  contactFormSubmissionEndpoint: "https://jsonplaceholder.typicode.com/todos/1",
  contactFormSuccessMessage: "We got your message.", // This should be a string
  contactSectionTitle: "We would love to hear from you.",
  contactSectionText: "Send us your questions or feature suggestions",
}

export default CommonData
