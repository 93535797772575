import features from "../../data/AppFeatures/features"
import "./index.scss"

const Features = () => {
  return (
    <div>
      <div className="container">
        {features.map(({ title, text, image }, idx) => (
          <div className={"row feature-block"} key={idx}>
            <div
              className={`col-sm-6 feature-image-bg-block ${
                idx % 2 !== 0 ? "order-sm-2" : ""
              }`}
            >
              <img src={image} alt={title} />
            </div>
            <div className={`col-sm-6 ${idx % 2 !== 0 ? "order-sm-1" : ""}`}>
              <div
                className={`feature-content ${
                  idx % 2 !== 0 ? "odd-content" : ""
                }`}
              >
                <h4>{title}</h4>
                <p dangerouslySetInnerHTML={{ __html: text }} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Features
