import React from "react"

import PrivacyPolicy from "../components/PrivacyPolicy"

function PrivacyPolicyPage() {
  return (
    <div>
      <PrivacyPolicy />
    </div>
  )
}

export default PrivacyPolicyPage
