import { useState, useRef } from "react"
import SlickSlider from "react-slick"
import { images, content2, content1 } from "../../data/AppSlider/slides"

import "./index.scss"

const Slider = () => {
  const slider = useRef()

  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
  }

  return (
    <div
      className={"slider-container"}
      id={"features-wrap"}
      name={"features-wrap"}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 mb-3 mb-md-5 pb-3">
            <h2 className={"lined-title pt-3"}>
              <span>All your messages in OneApp</span>
            </h2>
          </div>
          <div className="col-md-5 col-lg-4 order-lg-2">
            <div>
              <SlickSlider {...settings} ref={slider}>
                {images.map((image, idx) => (
                  <div key={idx}>
                    <img
                      src={image}
                      alt={`OneApp Features - ${idx + 1}`}
                      className={"d-block w-100"}
                    />
                  </div>
                ))}
              </SlickSlider>
            </div>
          </div>
          <div className="col-md-7 col-lg-4 order-lg-1 d-lg-flex d-md-none d-lg-block">
            <ul className={"slider-ul left"}>
              {content1.map((data, idx) => (
                <Li
                  data={data}
                  key={idx}
                  idx={idx}
                  currentSlide={currentSlide}
                  slider={slider}
                />
              ))}
            </ul>
          </div>
          <div className="col-md-7 col-lg-4 order-lg-3 d-lg-flex">
            <ul className={"slider-ul d-none d-md-block d-lg-none"}>
              {content1.map((data, idx) => (
                <Li
                  data={data}
                  key={idx}
                  idx={idx}
                  currentSlide={currentSlide}
                  slider={slider}
                />
              ))}
            </ul>
            <ul className={"slider-ul"}>
              {content2.map((data, idx) => (
                <Li
                  data={data}
                  key={idx}
                  idx={idx + content1.length}
                  currentSlide={currentSlide}
                  slider={slider}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Slider

const Li = ({ data: { icon, title, text }, currentSlide, idx, slider }) => {
  return (
    <li key={idx}>
      <button
        className={`btn ${currentSlide === idx ? "active-slide" : ""}`}
        onClick={() => slider.current?.slickGoTo(idx)}
      >
        <div className="icon">{icon}</div>
        <div>
          <h6>{title}</h6>
          <p>{text}</p>
        </div>
      </button>
    </li>
  )
}
