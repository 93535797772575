import howItWorks from "../../data/HowItWorks/data"
import "./index.scss"

const HowItWorks = () => {
  return (
    <div id={"hiw-wrap"} name={"hiw-wrap"}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 mb-3 mb-md-5 pb-2">
            <h2>How it works</h2>
          </div>
        </div>

        <div className="row justify-content-center">
          {howItWorks.map(({ icon, text }, idx) => (
            <div className="col-md-6 col-lg-4" key={idx}>
              <div className={`hiw-box hiw-box-${idx + 1}`}>
                <div>
                  {idx % 2 === 0 && <img src={icon} alt="" />}
                  <p>{text}</p>
                  {idx % 2 !== 0 && <img src={icon} alt="" />}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
