import React, { useState } from "react"
import Logo from "../../images/logo.png"
import CommonData from "../../data/CommonData"
import "./index.scss"

import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"

const menuLinks = [
  {
    label: "Cool Features",
    linkHash: "features-wrap",
  },
  {
    label: "How Do I...?",
    linkHash: "faq-wrap",
  },
  {
    label: "Contact Us",
    linkHash: "contact-wrap",
  },
]

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const [activeMenu, setActiveMenu] = useState("")

  const toggle = () => setIsOpen(!isOpen)

  const goTo = (e, id) => {
    if (isOpen) setIsOpen(false)
    e.preventDefault()
    setTimeout(() => {
      const element = document.getElementById(id)
      if (element) {
        setActiveMenu(id)
        element.scrollIntoView()
      }
    }, 150)
  }

  return (
    <Navbar light expand="md">
      <div className="container">
        <NavbarBrand href="/">
          <img src={Logo} alt="OneApp" width={70} />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {menuLinks.map(({ label, linkHash }) => {
              return (
                <NavItem key={linkHash}>
                  <NavLink
                    href={`#${linkHash}`}
                    onClick={e => goTo(e, linkHash)}
                    className={activeMenu === linkHash ? "active" : ""}
                  >
                    {label}
                  </NavLink>
                </NavItem>
              )
            })}

            <NavItem>
              <a
                href={CommonData.getOneAppLink}
                target={"_blank"}
                rel={"noreferrer"}
                className={"btn btn-primary ml-3"}
              >
                Get OneApp
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  )
}

export default Header
