import Header from "../components/Header"
import Hero from "../components/Hero"
import HowItWorks from "../components/HowItWorks"
import Slider from "../components/Slider"
import Features from "../components/Features"
import HowDoI from "../components/HowDoI"
import Testimonials from "../components/Testimonials"
import CTA from "../components/CTA"
import Contact from "../components/Contact"
import Footer from "../components/Footer"

function HomePage() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <HowItWorks />
      <Slider />
      <Features />
      <HowDoI />
      <Testimonials />
      <CTA />
      <Contact />
      <Footer />
    </div>
  )
}

export default HomePage
