import { FaFacebookSquare } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import CommonData from "../../data/CommonData"
import Up from "./up.svg"

import "./index.scss"

const icons = [
  { link: CommonData.facebookProfile, icon: <FaFacebookSquare /> },
  { link: CommonData.twitterProfile, icon: <FaTwitter /> },
  { link: CommonData.instagramProfile, icon: <FaInstagram /> },
]

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <button className="btn go-to-top" onClick={() => window.scroll(0, 0)}>
          <img src={Up} alt="Go to top" />
        </button>
        <div className="d-flex">
          <p>&copy; {new Date().getFullYear()} All Rights Reserved - OneApp</p>
          <p>
            <a
              className="pl-3"
              href={"/privacy-policy"}
              rel={"noreferrer"}
              target={"_blank"}
            >
              Privacy Policy
            </a>
          </p>
        </div>

        <ul className={"footer-social"}>
          {icons.map(({ link, icon }, idx) => (
            <li key={idx}>
              <a href={link} rel={"noreferrer"} target={"_blank"}>
                {icon}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  )
}

export default Footer
