import Slider from "react-slick"
import testimonials from "../../data/Testimonials/testimonials"

import "./index.scss"

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  autoplay: true,
}

const Testimonials = () => {
  return (
    <div className="testimonial-wrapper">
      <div className={"container"}>
        <Slider {...settings}>
          {testimonials.map(({ text, name, image }, idx) => (
            <div key={idx} className={"testimonial"}>
              <div className="testimonial-inner">
                <div className="img">
                  <img src={image} alt={name} />
                </div>
                <p className={"content"}>{text}</p>
                <p className={"name"}>{name}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Testimonials
